<template>
    <div>
        <v-Mtel></v-Mtel>
        <v-Mheader></v-Mheader>
        <v-Mbanner :bannerObj="bannerObj"></v-Mbanner>
        <v-Mintroduction></v-Mintroduction>
        <v-Mpatent></v-Mpatent>
        <v-Mbottom></v-Mbottom>
    </div>
</template>
<script>
import Mbanner from '@/components/Mbanner.vue'
import Mintroduction from '@/components/Mintroduction.vue'
import Mpatent from '@/components/Mpatent.vue'
import Mbottom from '@/components/Mbottom.vue'
import Mheader from '@/components/Mheader.vue'
import Mtel from '@/components/Mtel.vue'
export default {
    components: {
        'v-Mbanner': Mbanner,
        'v-Mintroduction': Mintroduction,
        'v-Mpatent': Mpatent,
        'v-Mbottom': Mbottom,
        'v-Mheader': Mheader,
        'v-Mtel': Mtel
    },
    data() {
        return {
            bannerObj: {
                title: '关于我们',
                desc: '科技助力救援 创新服务应急',
                culture: '让消防官兵少流血少流泪',
                img: require('@/assets/banner_02.png')
            }
        }
    }
}
</script>

