<template>
    <div class="bg">
        <div
            class="wrap"
        >浙江大虫科技股份有限公司始于2005年，是一家致力于应急救援C ISR系统研发的高科技公司，专注于应急救援领域的指挥、控制、通信、计算机与信息、监视、侦察领域技术研发，努力把应急救援C ISR系统打造应急救援部门的神经中枢，使之成为应急救援力量的倍增器。公司的主要产品有智慧应急指挥调度系统、灾害智能监控预警系统与各种救灾救援支持系统，业务覆盖消防、水域救援、森林防火等领域。</div>
        <div class="culture">
            <div class="top">企业文化</div>
            <van-swipe :autoplay="3000" indicator-color="white">
                <van-swipe-item
                    style="height: 100px;"
                    class="item"
                    v-for="(item,index) in items"
                    :key="index"
                >{{item.title}}{{item.text}}</van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    title: '愿景:',
                    text: '让消防官兵少流血少流泪。'
                },
                {
                    title: '使命:',
                    text:
                        '聚焦客户关注的挑战和压力，做客户用得起的，用得好的消防解决方案和服务。'
                },
                {
                    title: '价值观:',
                    text: '老实做人，踏实做事，让客户放心，让员工满意。'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.bg {
    width: 100%;
    height: 300px;
    background: url('../assets/bg.png') center center no-repeat;
    background-size: 100%;
    .wrap {
        width: 90%;
        margin: 20px auto;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 2px solid rgba(0, 138, 255, 1);
        padding: 12px;
        box-sizing: border-box;
        font-size: 12px;
    }
    .culture {
        margin: 60px auto;
        background: rgba(0, 138, 255, 1);
        box-shadow: 0px 6px 12px 0px rgba(0, 138, 255, 0.1);
        border-radius: 8px;
        border: 4px solid rgba(255, 255, 255, 1);
        position: relative;
        .top {
            color: rgba(0, 138, 255, 1);
            width: 140px;
            height: 40px;
            background: #fff;
            box-shadow: 0px 10px 20px 0px rgba(255, 255, 255, 0.2);
            border-radius: 40px;
            border: 4px solid rgba(0, 138, 255, 1);
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: #fff;
            font-size: 14px;
            padding: 0 24px;
            box-sizing: border-box;
            text-align: center;
        }
    }
}
</style>