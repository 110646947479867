<template>
    <van-row>
        <van-col span="24" class="wrap">
            <ul class="items">
                <li v-for="(item,index) of items" :key="index" class="item">
                    <span class="title">{{item.title}}</span>
                    <span class="content" :class="[index === 1 ? 'active' : '']">{{item.content}}</span>
                </li>
            </ul>
        </van-col>
    </van-row>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    title: '传真:',
                    content: '0573-83331659'
                },
                {
                    title: '公司电话:',
                    content: '0573-82828265'
                },
                {
                    title: '邮箱:',
                    content: 'jin0573@sohu.com'
                },
                {
                    title: '地址:',
                    content:
                        '浙江省嘉兴市南湖区嘉兴软件园汇信路153号15楼大虫科技'
                },
                {
                    title: '备案号:',
                    content: '浙ICP备2022005221号-1'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    background-color: #e8e8e8;
    margin-top: 32px;
    padding: 16px;
    box-sizing: border-box;
    .items {
        list-style: none;
        color: #666;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        .item {
            margin-bottom: 8px;
            text-align: center;
            .title {
                margin-right: 8px;
            }
        }
    }
}
</style>