<template>
    <div class="wrap">
        <span class="title">{{this.title}}</span>
    </div>
</template>
<script>
export default {
    props: ['title']
}
</script>
<style lang="scss" scoped>
.wrap {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .title {
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 138, 255, 1);
        margin-top: 30px;
        margin-bottom: 20px;
    }
}
</style>
