<template>
    <van-sticky class="wrap">
        <van-row class="contnet">
            <van-col span="16" class="img">
                <img src="@/assets/logo.png" class="logo" height="30" alt />
            </van-col>
            <van-col span="16" class="nav_wrap">
                <ul class="nav">
                    <router-link tag="li" to="/mhome">产品</router-link>
                    <router-link tag="li" to="/mabout">关于我们</router-link>
                    <router-link tag="li" to="/mnews">新闻中心</router-link>
                </ul>
            </van-col>
        </van-row>
    </van-sticky>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
    color: #fff;
    font-size: 14px;
    .contnet {
        background-color: #008aff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        .nav_wrap {
            .nav {
                display: flex;
                align-items: center;
                li {
                    opacity: 0.6;
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .router-link-active {
                    opacity: 1;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>