<template>
    <div class="wrap">
        <img src="@/assets/tel.png" width="26" alt />
        <span class="tel">0573-82828265</span>
    </div>
</template>
<script>
export default {}
</script>

<style lang="scss" scoped>
.wrap {
    position: fixed;
    z-index: 999;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: rgba(0, 138, 255, 1);
    border: 2px solid #7ac0fc;
    right: 5%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
        width: 180px;
    }
    .tel {
        color: #fff;
        margin-left: 16px;
        display: none;
        font-weight: bold;
    }
    &:hover .tel {
        display: block;
    }
}
</style>