<template>
    <div class="wrap">
        <van-image width="100%" :src="bannerObj.img" />
        <div class="text animated fadeInLeft">
            <span class="title">{{bannerObj.title}}</span>
            <span class="desc">{{bannerObj.desc}}</span>
            <div class="bar_wrap">
                <div class="bar"></div>
                <span>{{bannerObj.culture}}</span>
            </div>
        </div>
        <div class="img animated fadeInDown">
            <img src="../assets/banner_circle.png" width="100" alt />
        </div>
    </div>
</template>
<script>
export default {
    props: ['bannerObj'],
    data() {
        return {
            curHeight: ''
        }
    },
    beforeMount() {
        this.curHeight =
            document.documentElement.clientHeight || document.body.clientHeight
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    position: relative;
    overflow: hidden;
    .text {
        position: absolute;
        top: 30%;
        left: 10%;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        transform: translate3d(0, -30%, 0);
        .title {
            margin-bottom: 12px;
        }
        .desc {
            margin-bottom: 12px;
        }
        .bar_wrap {
            font-size: 10px;
            display: flex;
            align-items: center;
            .bar {
                width: 2px;
                height: 10px;
                background: rgba(255, 255, 255, 1);
                margin-right: 4px;
            }
        }
    }
    .img {
        position: absolute;
        top: 30%;
        right: 5%;
        transform: translate3d(0, -30%, 0);
    }
}
</style>